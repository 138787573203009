/* .ant-menu-item-selected{
    background-color: #49519f !important;
  }*/
  .ant-menu.ant-menu-dark{
    margin-top: 15px;
  }
   

   .ant-menu-item-selected{
    background-color: transparent !important;
  }
  .gx-layout-sider-header .gx-site-logo-small{
    display: none;
  }
  .ant-layout-sider-collapsed .gx-layout-sider-header .gx-site-logo-small{
    display: block;
  }
  
  .gx-layout-sider-header {
    padding: 10px 30px 10px 22px !important;
  }

.gx-site-logo img{
    width: 100%;
    height: 100%;
  }
